<template>
  <div class="btn-group" v-if="device==='desktop'">
    <button class="btn btn-outline-primary btnnoborder dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown">
      {{$t("sabre.search-result.sort-recommended")}}
    </button>

    <sort-content-radios propsClassName="dropdown-menu dropdownranger dropdown-menu-start" aria-labelledby="dropdownMenuClickableInside"/>

  </div>

  <sort-content-radios propsClassName="text-right filttermobailboby" v-else/>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    SortContentRadios: () => import('./sortContentRadios'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
  },
};
</script>
